import React from "react"
import { FooterPageLayout } from "../components/Global/Layouts"

const TermsOfServicePage = () => (
  <FooterPageLayout
    type={"terms"}
    title={"Terms Of Service"}
    description={"The terms of service page :o"}
    query={"query { shop { termsOfService { body } } }"}
  />
)

export default TermsOfServicePage
